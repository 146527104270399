<template>
	<div>
		<Navbar/>
		<div :style="{'background-image': `url(${require('../assets/img/cover3.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase"> Legal </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						Conditions générales d'utilisation
					</h3>
				</div>
			</div>
		</div>

		<div class="bg-white py-12 mx-8">
			<div class="container mx-auto">
				<p><strong>– CONDITIONS GENERALES DE SERVICE – Version du 01 Janvier 2020&nbsp; –&nbsp; hydrossas.com</strong></p>
				<p>Les présentes Conditions Générales de Service sont conclues entre :</p>
				<p>– D’une part, société Hydrochain SAS, société de droit français déclaré au Greff du Commerce de Paris &nbsp;identifié par le numéro RCS 841 795 792<strong>,</strong>&nbsp;dont le siège est situé au 79 AV DU DOCTEUR ARNOLD NETTER - 75012 PARIS , représenté par M GOFFIC Pierre et éditeur du site internet https://www.hydrosaas.com/,&nbsp; ci-après dénommée Hydrossas.com;</p>
				<p>– Et d’autre part, toute personne morale ou physique, ci-après nommée le Client, qu’elle soit de droit public ou de droit privé, s’engageant pour un ou plusieurs services fournis par Hydrosaas.com ;</p>
				<p><strong>Article 1 : Objet –</strong></p>
				<p><strong>1.1</strong>&nbsp;Les présentes Conditions Générales de Services régissent le cadre dans lequel Hydrosaas.com fournit&nbsp; au Client le (les) service (services) au(x)quel(s) le Client a souscrit, que ce soit au titre d’une offre gratuite ou d’un offre payante.</p>
				<p><strong>1.2</strong>&nbsp;Les présentes Conditions Générales de Service sont éventuellement complétées par des Conditions Particulières applicables au(x) service(s) souscrits par le Client auprès de Hydrosaas.com.</p>
				<p><strong>1.3</strong>&nbsp;En cas de contradiction(s) entre les termes des présentes Conditions Générales de Services et les&nbsp;éventuelles Conditions Particulières applicables en fonction du (des) service(s) souscrit(s) par le Client, les dispositions contractuelles des présentes Conditions Générales de Services prévaudront.</p>
				<p><strong>1.4</strong>&nbsp;La souscription du Client à une (des) offre(s) proposées par Hydrosaas.com implique la pleine compréhension par le Client des présentes Conditions Générales de Service et des éventuelles Conditions Particulières et leur acceptation sans réserve par le Client.</p>
				<p><strong>1.5</strong>&nbsp;Les présentes Conditions Générales de Service sont conclues entre les parties pendant toute la durée de la fourniture du (des) service(s) souscrit(s) par le Client auprès de Hydrosaas.com.</p>
				<p><strong>1.6</strong>&nbsp;Le Client reconnait avoir reçu de Hydrosaas.com toutes les informations nécessaires à sa compréhension du (des) service(s) souscrit(s).</p>
				<p><strong>1.7</strong>&nbsp;Aucune tolérance de la part de Hydrosaas.com dans l’exécution des présentes Conditions Générales de Service ou des Conditions Particulières éventuellement applicables ne pourra être interprétée comme valant renonciation aux termes des présentes ou des Conditions Particulières applicables.</p>
				<p><strong>Article 2 : Droit applicable et attribution de compétence –</strong></p>
				<p><strong>2.1</strong>&nbsp;Les présentes Conditions Générales de Service et les éventuelles Conditions Particulières applicables sont régies par la législation française.</p>
				<p><strong>2.2</strong>&nbsp;Pour tout litige relatif à l’application, l’interprétation et aux conséquences des présentes Conditions Générales de Service et des éventuelles Conditions Particulières applicables et après tentative de conciliation à l’amiable, attribution expresse et exclusive de compétence est faite aux tribunaux compétents du ressort de Paris.</p>
				<p><strong>Article 3 : Responsabilité et obligations de </strong><strong>Hydrosaas.com –</strong></p>
				<p><strong>3.1</strong>&nbsp;<strong>Hydrosaas.com</strong> assume une obligation de moyens aux titres des présentes. Dans ce cadre, <strong>Hydrosaas.com</strong> veillera à apporter toute la diligence requise pour fournir un service de qualité.</p>
				<p><strong>3.2</strong>&nbsp;<strong>Hydrosaas.com </strong>ne saurait être tenu(s) pour responsable(s) de l’indemnisation des dommages directs et/ou indirects subis par le Client, notamment en raison de l’exécution ou de la mauvaise exécution du (des) service(s).</p>
				<p><strong>3.3</strong>&nbsp;Dans le cas d’un manquement à l’une des obligations contractuelles essentielles résultant d’une négligence légère de la part de Hydrosaas.com, la responsabilité de Hydrosaas.com est strictement limitée au dommage normalement prévisible découlant directement de cette défaillance dans les limitations prévues à l’article 3.4.</p>
				<p><strong>3.4</strong> Toutefois, si la responsabilité de Hydrosaas.com était retenue, le Client ne pourrait prétendre qu’au montant de 100€ (cent euros) au titre d’indemnités et/ou de dommages et intérêts par sinistre pour tout préjudice reconnu.</p>
				<p><strong>3.5</strong>&nbsp;Les limites de responsabilités présentes aux articles 3.1, 3.2 et 3.3, 3.4 des présentes Conditions Générales de Service s’appliquent à l’exécution des Conditions Particulières éventuellement applicables.</p>
				<p><strong>3.6</strong>&nbsp;Les limites de responsabilités présentes aux articles 3.1, 3.2, 3.3 et 3.4 des présentes Conditions Générales de Service s’appliquent également au bénéfice des collaborateurs,&nbsp;des agents d’exécution et des partenaires de Hydrosaas.com.</p>
				<p><strong>3.7</strong>&nbsp;Le Client reconnait que les services de Hydrosaas.com ne pourraient être mis à disposition dans la forme proposée sans les limites de responsabilités évoquées aux articles 3.1, 3.2, 3.3, 3.4 et 3.5 des présentes Conditions Générales de Service.</p>
				<p><strong>Article 4 : Responsabilité et obligations du Client –</strong></p>
				<p><strong>4.1</strong>&nbsp;Le Client s’engage à respecter, dans les contenus qu’il diffuse via le(s) service(s) fourni(s) par Hydrosaas.com, les prescriptions légales et règlementaires en vigueur et plus particulièrement le droit de propriété intellectuelle.</p>
				<p><strong>4.2</strong>&nbsp;Le Client accepte toutes les obligations légales découlant de l’utilisation du/des service(s) fourni(s) par Hydrosaas.com, notamment en ce qui concerne le droit à la propriété intellectuelle, sans que Hydrosaas.com ne puisse être inquiété.</p>
				<p><strong>4.3</strong>&nbsp;Toute activité du Client réalisée par l’intermédiaire du/des service(s) fourni(s) par Hydrosaas.com susceptible d’entrainer une responsabilité civile et/ou pénale pour Hydrosaas.com est strictement interdite.</p>
				<p><strong>4.4</strong>&nbsp;Toute violation des points visés aux articles 4.1, 4.2 et 4.3 pourra entrainer la suspension immédiate du/des service(s) du Client par Hydrosaas.com, sans préavis, mise en demeure ou préjudice. Cette suspension pourra être provisoire jusqu’à régularisation ou définitive entrainant la résiliation de plein droit du présent contrat sans qu’aucun remboursement ou aucune indemnité ne puisse être réclamée par le Client à Hydrosaas.com.</p>
				<p><strong>4.5</strong>&nbsp;Le/Les service(s) du Client pourront être suspendus immédiatement par Hydrosaas.com sans préavis, mise en demeure ou préjudice dans le cas ou Hydrosaas.com recevrait à cet effet un avis notifié par une autorité compétente, administrative ou judiciaire, conformément aux lois et règlementations en vigueur. Cette suspension pourra être provisoire jusqu’à régularisation ou définitive entrainant la résiliation de plein droit du présent contrat sans qu’aucun remboursement ou aucune indemnité ne puisse être réclamée par le Client à Hydrosaas.com.</p>
				<p><strong>4.6</strong>&nbsp;Le/Les service(s) du Client pourront être suspendus immédiatement par Hydrosaas.com sans préavis, mise en demeure ou préjudice dans le cas ou &nbsp;Hydrosaas.com recevrait une demande d’un tiers conformément à la loi pour la Confiance dans l’Economie Numérique du 21 Juin 2004 si le caractère manifestement illicite est porté à la connaissance de Hydrosaas.com. Cette suspension pourra être provisoire jusqu’à régularisation ou définitive entrainant la résiliation de plein droit du présent contrat sans qu’aucun remboursement ou aucune indemnité ne puisse être réclamée par le Client à Hydrosaas.com.</p>
				<p>&nbsp;</p>
				<p><strong>4.7</strong>&nbsp;Le Client est responsable des données d’accès à son/ses service(s) qui lui sont transmise(s). Ces données d’accès sont strictement confidentielles et ne sont destinés qu’au seul usage du Client. Toute divulgation, volontaire ou non, à des tiers de ses données d’accès ou d’éléments de ses données d’accès n’engage que la responsabilité du Client à l’exclusion de celle de Hydrosaas.com.</p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p><strong>4.8</strong> En cas de suspicion de perte ou de vol des identifiants d’accès du Client ou de suspicion d’accès non autorisé sur un espace sécurisé du Client, Hydrosaas.com se réserve le droit de suspendre, par mesure de sécurité, le(s) service(s) concerné(s) en attente d’une réponse du Client.</p>
				<p>&nbsp;</p>
				<p><strong>Article 5 : Identification des clients –</strong></p>
				<p>&nbsp;</p>
				<p>&nbsp;</p>
				<p><strong>5.1</strong> Le Client s’engage à communiquer à Hydrosaas.com les informations permettant de l’identifier formellement. Cela inclut son nom, son prénom, une adresse postale, son pays de résidence et une adresse de courrier électronique valide.</p>
				<p><strong>5.2</strong> Conformément à l’article 4.1 Hydrosaas.com pourra demander au Client de fournir une photocopie de pièce d’identité avant l’activation de certains services.</p>
				<p><strong>5.3</strong> Le Client s’engage à transmettre à Hydrosaas.com toute mise à jour des données spécifiées à l’article 5.1 dans un délai de quinze jours après leur changement via l’interface à cet effet accessible dans l’espace client sur le site internet&nbsp;<strong>https://www.</strong> hydrosaas.com /.</p>
				<p><strong>5.4</strong> Dans le cas ou le Client fournirait intentionnellement des informations d’identification fausses, inexactes, périmées ou incomplètes, cela pourra entrainer la suspension immédiate du/des service(s) du Client par Hydrosaas.com, sans préavis, mise en demeure ou préjudice. Cette suspension pourra être provisoire jusqu’à régularisation ou définitive entrainant la résiliation de plein droit du présent contrat sans qu’aucun remboursement ou aucune indemnité ne puisse être réclamée par le Client à Hydrosaas.com.</p>
				<p><strong>5.5</strong> Hydrosaas.com se réserve le droit de vérifier les adresses IP utilisées par le Client pour se connecter à l’espace client de gestion de son (ses) service(s) et de les comparer aux informations fournies, notamment en ce qui concerne son adresse géographique.</p>
				<p><strong>Article 6 : Cas de force majeure –</strong></p>
				<p><strong>6.1</strong>&nbsp;Tout événement présentant des caractères d’imprévisibilité, d’extériorité à l’une ou l’autre des parties et d’irrésistibilité est considéré comme un cas de force majeure et suspend à ce titres les obligations des présentes Conditions Générales de Service et des Conditions Particulières applicables.</p>
				<p><strong>6.2</strong>&nbsp;Sont considérés comme cas de force majeure dans le cadre de l’article 6.1 les évènements suivants, sans que cette liste soit limitative, les catastrophes naturelles et industrielles, les grèves, les guerres civiles ou étrangères, les attentats, les émeutes, les mouvements populaires, une défaillance matérielle ou la défaillance d’un ou de plusieurs réseaux de communication mettant l’une ou l’autre partie dans l’incapacité de respecter les termes du présent contrat.</p>
				<p><strong>Article 7 : Droit de rétractation</strong></p>
				<p><strong>13.1&nbsp;</strong>Conformément au Code de la Consommation pris en son article L 121-21-8, 3° « le droit de rétractation ne peut être exercé (…) pour les contrats de fourniture de biens confectionnés selon les spécifications du consommateur ou nettement personnalisés (…) ».</p>
				<p><strong>13.2&nbsp;</strong>Conformément au Code de la Consommation pris en son article L121-21-8, 1°, le Client ne peut exercer son droit de rétractation pour les Services pleinement exécutés avant la fin du délai de rétractation et dont l’exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation. A compter de la validation du paiement, le Client disposera de l’accès à son Serveur Privé Virtuel.&nbsp;Le Client reconnaît donc formellement et accepte que, dans toutes les hypothèses où il accepte expressément l’exécution du Service ou utilise le Service, et renonce expressément à exercer son droit de rétractation avant l’expiration du délai de rétractation, il ne pourra valablement invoquer ce droit</p>
				<p><strong>13.3</strong>&nbsp;Il en est de même en cas de changement de configuration.</p>
				<p><strong>Article 8 : Prix, paiement, durée, activation et renouvellement des services –</strong></p>
				<p><strong>8.1</strong>&nbsp;Les prix sont indiqués sur le site&nbsp;<strong>https://www.</strong>hydrosaas.com/.</p>
				<p><strong>8.2</strong>&nbsp;Le montant total à payer est indiqué sur le bon de commande créé pour chaque commande ou renouvellement de service(s) dans l’espace d’administration du Client sur le site de Hydrosaas.com. Ce montant est payable en euros.</p>
				<p><strong>8.3</strong>&nbsp;Le montant facturé au Client est le prix en vigueur lors de la validation par le Client du bon de commande.</p>
				<p><strong>8.4</strong>&nbsp;En cas de différence entre le prix affiché sur le site internet ou sur tout autre support publicitaire et le prix affiché sur le bon de commande, seul le prix affiché sur le bon de commande fait foi.</p>
				<p><strong>8.5</strong>&nbsp;En cas de modification de tarif émise entre le moment ou l’utilisateur a généré son bon de commande, et le moment ou celui-ci effectue son règlement, Hydrosaas.com se réserve le droit d’annuler la commande et rembourse le client.</p>
				<p><strong>8.6</strong>&nbsp;Hydrosaas.com se réserve le droit de modifier à tout moment les prix des services proposés.</p>
				<p><strong>8.7</strong>&nbsp;La durée du (des) service(s) souscrit(s) par le Client auprès de Hydrosaas.com est indiquée sur le bon de commande.</p>
				<p><strong>8.8</strong>&nbsp;La durée des services proposés au Client par Hydrosaas.com à titre gratuit est indéterminée.</p>
				<p><strong>8.9</strong>&nbsp;Avant l’expiration du (des) service(s) du Client, Hydrosaas.com en informe le client par au moins 3 (trois) courriers électroniques à l’adresse de contact indiqué sur le compte client.&nbsp; De plus, la (les) date(s) d’expiration(s) du (des) service(s) du Client sont indiquées dans son espace client.</p>
				<p><strong>8.10</strong>&nbsp;Le Client pourra renouveler son (ses) service(s) avant leur expiration, au prix en vigueur au jour du renouvellement. Ce renouvellement donne lieu, comme indiqué à l’article 8.2 des présentes Conditions Générales de Service à création d’un nouveau bon de commande.</p>
				<p><strong>8.11</strong>&nbsp;Les moyens de paiement acceptés par Hydrosaas.com sont ceux indiqués sur le bon de commande le jour du paiement.</p>
				<p><strong>8.12</strong>&nbsp;Une fois le bon de commande créé par Hydrosaas.com, le Client dispose d’un délais de vingt-quatre (24) heures pour en effectuer le règlement. En cas de paiement par chèque bancaire ou postal, ce délais est porté à quarante-huit (48) heures ouvrables, le cachet de la poste faisant foi. En cas de paiement par virement bancaire, ce délais est porté à 48 (quarante-huit) heures, la date d’émission du virement bancaire faisant foi.</p>
				<p><strong>8.13</strong>&nbsp;En cas de règlement du bon de commande par le Client après le délais de vingt-quatre (24) heures ou de quarante huit (48) heures selon le moyen de paiement, le Client est redevable des éventuels changement tarifaires survenus entre la création du bon de commande et son règlement. Dans ce cas, le Client devra régler le réajustement tarifaire ou demander un remboursement des sommes déjà versée et la résiliation de plein droit du présent contrat, par dérogation aux articles 9.1 et suivants des présentes.</p>
				<p><strong>8.14</strong>&nbsp;Une fois la preuve de paiement du bon de commande reçue et approuvée, Hydrosaas.com procède à l’activation du (des) service(s) au(x)quel(s) le Client a souscrit dans des délais raisonnables et n’est soumis qu’à une obligation de moyens.</p>
				<p><strong>8.15&nbsp;</strong>Une fois le service du client expiré, Hydrosaas.com laisse 48h au client pour renouveler son service. Une fois ce délai dépassé le produit est supprimé.</p>
				<p><strong>8.16</strong>&nbsp;Pour toute acte d’infogérance à caractère technique (VPS, serveur dédié ou pour les domaines) , veuillez contacter le support afin de connaître le prix , pour information une heure d’infogérance chez Hydrosaas.com est facturé 80 €HT de l’heure .</p>
				<p><strong>Article 9 : Echéance du contrat, annulation de commande et résiliation</strong></p>
				<p><strong>9.1</strong>&nbsp;Conformément à l’article 1.5 des présentes, le contrat est résilié de plein droit à son échéance.</p>
				<p><strong>9.2</strong>&nbsp;Le renouvellement du contrat s’effectue dans les conditions prévues aux articles 8.7 et 8.8 des présentes Conditions Générales de Service.</p>
				<p><strong>9.3</strong>&nbsp;En cas de commande d’un produit non disponible en stock, le client peut demander l’annulation de sa commande dans un délais de 7 jours. La commande est alors remboursée sous forme d’un avoir sur Porte monnaie électronique, ou sous forme de chèque bancaire. Si l’annulation intervient au delà de 7 jours, le remboursement de la commande n’est pas garantie, notamment si le matériel correspondant à la commande a été expédié de notre fournisseur afin d’être mis en service. L’éventuel remboursement ne peut alors être réalisé que sur Porte Monnaie électronique uniquement.</p>
				<p><strong>9.4</strong>&nbsp;Chaque partie peut résilier sans indemnité d’aucune sorte et de plein droit le contrat en cas de force majeure subsistant pour une durée supérieure à trente (30) jours calendaires.</p>
				<p><strong>9.5</strong>&nbsp;Le Client est libre de résilier de plein droit le présent contrat avant son échéance sans pouvoir prétendre au remboursement par Hydrosaas.com des sommes déjà versées en contactant le Service Commercial de Hydrosaas.com dont les coordonnées sont disponibles sur le site internet&nbsp;<strong>https://www.</strong><strong>Hydrosaas.com/</strong></p>
				<p><strong>9.6</strong>&nbsp;Hydrosaas.com peut résilier les services proposés au Client à titre gratuit à tout moment en respectant un préavis de quatorze (14) jours calendaires. Hydrosaas.com notifiera le Client de cette décision par tout moyen de communication jugé approprié, y compris par courrier électronique.</p>
				<p><strong>Article 10 : Modifications des présentes Conditions Générales de Service</strong></p>
				<p><strong>10.1</strong>&nbsp;Le Client et Hydrosaas.com conviennent expressément que Hydrosaas.com pourra porter des modifications à ses Conditions Générales de Service et aux éventuelles Conditions Particulières qui lui seraient rattachées sans autre devoir d’information envers le Client que d’en mettre la dernière version en ligne sur son site internet&nbsp;<strong>https://www.</strong><strong>Hydrosaas.com</strong>/.</p>
				<p><strong>10.2</strong>&nbsp;En cas de modifications portées aux présentes Conditions Générales de Service par Hydrosaas.com, le Client pourra, en dérogation à l’articles 9 des présentes, résilier le contrat dans un délais de quatorze (14) jours à compter de l’entrée en vigueur des modifications.</p>
				<p><strong>Article 11 : Service abuse et SPAM</strong></p>
				<p><strong>11.1&nbsp;</strong>Le client s’engage expressément à une obligation de résultat quand à la gestion et le traitement des ABUSE qui lui seront transmis par le service juridique de Hydrosaas.com dans le cadre des activités du client.</p>
				<p><strong>11.2&nbsp;</strong>En cas de non réponse à un abuse transmis sous 24h ou dans le cas ou de nombreux abuses n’ont pas étés traités conformément aux dispositions légales et contractuelles, Hydrosaas.com se réserve le droit de suspendre ou de résilier les services concernés sans préavis ni remboursement d’aucune somme.</p>
				<p><strong>11.3&nbsp;</strong>En cas de blacklistage par une RBL ou par un outils de listing de mails non désirés, Hydrosaas.com se réserve le droit de suspendre, dérouter ou résilier les IP Fail Over, les services dédiés, virtualisés, mutualisés ou tout autre service qui serait utilisé par le client à des fins d’envois de mail de masse et qui ne respectent pas la Netiquette instauré par la CNIL, notamment en ce qui concerne la gestion des OPT-IN, des achats/reventes de listing d’e-mail, ou de respect du lien de désinscription.</p>
				<p><strong>11.4&nbsp;</strong>Toute infraction à ces règles de gestion d’abuse entraineront systématiquement la résiliation unilatérale des services concernés, voire, une résiliation de l’ensemble des services du compte client concerné. Le client accepte cette règle et ses conséquences dans le cas ou son activité entraine ou risque d’entrainer l’émission ou la réception de nombreux abuses.</p>
				<p><strong>Article 12 : Dispositions générales</strong></p>
				<p><strong>12.1</strong>&nbsp;La nullité d’une des clauses des présentes Conditions Générales de Service ou des Conditions Particulières applicables, en application d’une loi, d’un règlement, ou d’une décision définitive prononcée par une juridiction compétente n’entrainera pas la nullité des autres dispositions qui conserveront leur force et leur portée.</p>
				<p><strong>12.2</strong>&nbsp;Le Client autorise expressément Hydrosaas.com à sous-traiter tout ou partie du (des) service(s) au(x)quel(s) il a souscrit.</p>
				<p><strong>12.3</strong>&nbsp;Le Client autorise expressément Hydrosaas.com à se prévaloir des services qui lui sont fournis pour des actions de publicité et de promotion.</p>
				<p><strong>12.4</strong>&nbsp;Les intitulés des articles des présentes Conditions Générales de Service et des Conditions Particulières applicables ont pour seul but d’en faciliter la lecture et la compréhension et n’ont pas de valeur contractuelle.</p>
				<p><strong>Article 13 : Droit de rétractation</strong></p>
				<p><strong>13.1&nbsp;</strong>Conformément au Code de la Consommation pris en son article L 121-21-8, 3° « le droit de rétractation ne peut être exercé (…) pour les contrats de fourniture de biens confectionnés selon les spécifications du consommateur ou nettement personnalisés (…) ».</p>
				<p><strong>13.2&nbsp;</strong>Conformément au Code de la Consommation pris en son article L121-21-8, 1°, le Client ne peut exercer son droit de rétractation pour les Services pleinement exécutés avant la fin du délai de rétractation et dont l’exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation.. A compter de la validation du paiement, le Client disposera de l’accès à son Serveur Privé Virtuel.&nbsp;Le Client reconnaît donc formellement et accepte que, dans toutes les hypothèses où il accepte expressément l’exécution du Service ou utilise le Service, et renonce expressément à exercer son droit de rétractation avant l’expiration du délai de rétractation, il ne pourra valablement invoquer ce droit</p>
				<p><strong>13.3</strong>&nbsp;Il en est de même en cas de changement de configuration.</p>
				<p>&nbsp;</p>
			</div>
		</div>

		<div class="bg-gray-50">
			<Footer />
		</div>
	</div>
</template>

<script>
	import Navbar from "@/components/Navbar";
	import Footer from "@/components/Footer";
	export default {
		name: 'ToS',
      metaInfo: () => ({
        title: 'HydroSaas',
        titleTemplate: '%s | Conditions générales d\'utilisation',
        meta: [
          { name: 'description', content: 'Conditions générales d\'utilisation du site HydroSaaS et des produits HydroSaaS : VPN, serveurs web, Cloud VPS.' },
          { name: 'og:title', content: 'Conditions d\'utilisation des services HydroSaaS' },
          { name: 'twitter:title', content: 'Conditions d\'utilisation des services HydroSaaS' },
          { name: 'og:description', content: 'Conditions générales d\'utilisation du site HydroSaaS et des produits HydroSaaS : VPN, serveurs web, Cloud VPS.' },
          { name: 'twitter:description', content: 'Conditions générales d\'utilisation du site HydroSaaS et des produits HydroSaaS : VPN, serveurs web, Cloud VPS.' },
          { name: 'twitter:image', content: 'https://hydrosaas.com/default.png' },
          { name: 'og:image', content: 'https://hydrosaas.com/default.png' },
        ],
      }),
      htmlAttrs: {
        amp: true
      },
		components: {
			Footer,
			Navbar,
		},
	}
</script>
